import React from 'react';
import { Link } from 'react-router-dom';

const BlogDetail = () => {
  // Example data for similar blogs
  const similarBlogs = [
    {
      id: 1,
      title: 'The Role of Technology in Transforming Agricultural Waste Management',
      image: 'https://images.unsplash.com/photo-1508857650881-64475119d798?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      category: 'Tech',
      date: 'July 6, 2024',
    },
    {
      id: 2,
      title: 'Sustainable Farming Practices',
      image: 'https://images.unsplash.com/photo-1530541835461-dedaf9cf368a?q=80&w=1587&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      category: 'Sustainability',
      date: 'July 4, 2024',
    },
    {
      id: 3,
      title: 'Future Trends in Precision Farming',
      image: 'https://images.unsplash.com/photo-1518994603110-1912b3272afd?q=80&w=1448&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      category: 'Tech',
      date: 'July 3, 2024',
    },
    {
      id: 4,
      title: 'Organic Farming: Benefits and Challenges',
      image: 'https://images.unsplash.com/photo-1516253593875-bd7ba052fbc5?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      category: 'Sustainability',
      date: 'July 2, 2024',
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mx-auto">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <img
            src="https://images.unsplash.com/photo-1516253593875-bd7ba052fbc5?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            className="w-full h-80 object-cover object-center"
            alt="Agriculture Blog"
          />
          <div className="p-6">
            <h1 className="text-3xl font-bold text-gray-800 mb-4">Biofuels and Rural Development: Empowering Communities</h1>
            <p className="text-sm text-gray-600 mb-2">Published on Sep 5, 2024</p>
            <p className="text-base text-gray-700 leading-relaxed mb-4">
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">Introduction</h2>
            In recent years, the quest for sustainable energy sources has gained momentum, with biofuels emerging as a promising alternative to fossil fuels. Beyond their environmental benefits, biofuels have the potential to transform rural communities by creating economic opportunities and fostering development. In this blog post, we will explore how biofuels can empower rural communities and contribute to their sustainable development.
            </p>
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">The Potential of Biofuels</h2>
            <p className="text-base text-gray-700 leading-relaxed mb-4">
            Biofuels, derived from organic materials such as plants and waste, offer a renewable alternative to traditional fossil fuels. Unlike fossil fuels, which contribute to greenhouse gas emissions and environmental degradation, biofuels are considered carbon-neutral, as the carbon dioxide released during combustion is offset by the carbon dioxide absorbed by the plants during growth. This makes biofuels a crucial component of efforts to mitigate climate change and transition to a low-carbon economy.
            </p>
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">Empowering Rural Communities</h2>
            <p className="text-base text-gray-700 leading-relaxed mb-4">
            One of the most significant advantages of biofuel production is its potential to empower rural communities. 
            By investing in biofuel production facilities, rural areas can harness their natural resources and agricultural expertise to create a sustainable source of income. 
            Biofuel production often relies on locally grown feedstocks such as corn, sugarcane, soybeans, and biomass, providing farmers with a new market for their crops.
            </p>
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">Job Creation</h2>
            <p className="text-base text-gray-700 leading-relaxed mb-4">
            Biofuel production facilities require a skilled workforce to operate, offering employment opportunities for local residents. From farming and harvesting feedstocks to operating production facilities and managing distribution networks, biofuel production creates jobs across the entire supply chain. By investing in biofuel infrastructure, rural communities can reduce unemployment, boost incomes, and improve overall living standards.
            </p>
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">Economic Growth</h2>
            <p className="text-base text-gray-700 leading-relaxed mb-4">
            In addition to creating jobs, biofuel production can stimulate economic growth in rural areas. 
            As biofuel production facilities become operational, they attract investment, infrastructure development, and ancillary businesses. Local economies benefit from increased economic activity, with more money circulating within the community. 
            Moreover, biofuel production can reduce dependency on imported fossil fuels, saving money and improving energy security.
            </p>
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">Environmental Benefits</h2>
            <p className="text-base text-gray-700 leading-relaxed mb-4">
            Beyond their economic advantages, biofuels offer significant environmental benefits. 
            By replacing fossil fuels, biofuels help reduce greenhouse gas emissions, improve air quality, and mitigate climate change. 
            Additionally, biofuel production can help restore degraded land, improve soil health, and protect biodiversity. 
            By promoting sustainable land management practices, biofuel production contributes to the long-term environmental health of rural areas.
            </p>
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">Challenges and Considerations</h2>
            <p className="text-base text-gray-700 leading-relaxed mb-4">
            While biofuels offer many benefits, their widespread adoption faces several challenges. 
            Competition for land and water resources, concerns about food security, and potential environmental impacts are important considerations that must be addressed. 
            Additionally, the sustainability of biofuel production depends on factors such as feedstock selection, production methods, and supply chain management. 
            To maximize the benefits of biofuels, it is essential to adopt policies and practices that promote sustainable production and ensure that the benefits are equitably distributed among communities.
            </p>
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">Conclusion</h2>
            <p className="text-base text-gray-700 leading-relaxed mb-4">
            Biofuels have the potential to transform rural communities by creating economic opportunities, reducing dependency on fossil fuels, and mitigating climate change. 
            By investing in biofuel production, rural areas can harness their natural resources, create jobs, and foster sustainable development. 
            However, realizing the full potential of biofuels requires careful planning, investment, and commitment from governments, businesses, and communities. 
            With the right policies and practices in place, biofuels can empower rural communities and contribute to a more sustainable and prosperous future for all.
            </p>
          </div>
        </div>

        {/* Similar Blogs Section */}
        <div className="mt-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">Similar Blogs</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {similarBlogs.map((blog) => (
              <div key={blog.id} className="bg-white rounded-lg overflow-hidden shadow-lg">
                <img src={blog.image} className="w-full h-48 object-cover object-center" alt={blog.title} />
                <div className="p-4">
                  <span className="text-sm text-gray-600">{blog.category}</span>
                  <h3 className="text-lg font-semibold text-gray-800 mt-2 mb-3">{blog.title}</h3>
                  <p className="text-sm text-gray-700">{blog.date}</p>
                  <Link to={`/blogdetail/${blog.id}`} className="block mt-2 bg-[#f6b60d] hover:bg-[#d99d0c] text-white px-4 py-2 rounded-lg shadow-md">Read Article</Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
