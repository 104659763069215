import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postRequirementAction } from '../../redux/actions';

const PostRequirement = () => {
  const [formData, setFormData] = useState({
    category: '',
    product: '',
    availableQuantity: '',
    pincode: '',
    companyName: '',
    email: '',
    mobileNumber: '',
    comment: '',
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postRequirementAction(formData)); // Dispatching the post requirement action
    console.log('Form submitted: ', formData);
    navigate('/requirements'); // Navigate to the requirements page after submission
  };

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-6">Post Your Requirement</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        
        {/* Category */}
        <div>
          <label htmlFor="category" className="block text-lg font-medium">
            Category:
          </label>
          <select
            name="category"
            value={formData.category}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border rounded-md"
            required
          >
            <option value="">Select Category</option>
            <option value="raw-material">Raw Material</option>
            <option value="finished-product">Finished Product</option>
            <option value="service">Service</option>
          </select>
        </div>

        {/* Product */}
        <div>
          <label htmlFor="product" className="block text-lg font-medium">
            Product:
          </label>
          <input
            type="text"
            name="product"
            value={formData.product}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border rounded-md"
            required
          />
        </div>

        {/* Available Quantity */}
        <div>
          <label htmlFor="availableQuantity" className="block text-lg font-medium">
            Available Quantity:
          </label>
          <input
            type="number"
            name="availableQuantity"
            value={formData.availableQuantity}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border rounded-md"
            required
          />
        </div>

        {/* Pincode */}
        <div>
          <label htmlFor="pincode" className="block text-lg font-medium">
            Pincode:
          </label>
          <input
            type="text"
            name="pincode"
            value={formData.pincode}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border rounded-md"
            required
          />
        </div>

        {/* Company Name */}
        <div>
          <label htmlFor="companyName" className="block text-lg font-medium">
            Company Name:
          </label>
          <input
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border rounded-md"
            required
          />
        </div>

        {/* Email */}
        <div>
          <label htmlFor="email" className="block text-lg font-medium">
            E-mail:
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border rounded-md"
            required
          />
        </div>

        {/* Mobile Number */}
        <div>
          <label htmlFor="mobileNumber" className="block text-lg font-medium">
            Mobile Number:
          </label>
          <input
            type="tel"
            name="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border rounded-md"
            required
          />
        </div>

        {/* Comment */}
        <div>
          <label htmlFor="comment" className="block text-lg font-medium">
            Comment:
          </label>
          <textarea
            name="comment"
            value={formData.comment}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border rounded-md"
            rows="4"
          />
        </div>

        <button
          type="submit"
          className="bg-[#f6b60d] hover:bg-[#e5a63a] text-white font-bold py-2 px-4 rounded"
        >
          Submit Requirement
        </button>
      </form>
    </div>
  );
};

export default PostRequirement;
