import React, { useState } from 'react';

const ProductDetail = () => {
  // const [currentPage, setCurrentPage] = useState(1);
  // const productsPerPage = 4;

  const products = [
    {
      title: 'Bagasse',
      description: "Bagasse is the fibrous residue left after extracting juice from sugarcane, often used for biofuel or paper production.",
      image: '../assets/img/products/Bagasse.png',
    },
    {
      title: 'Saw Dust Briquettes',
      description: 'Sawdust briquettes are compressed wood sawdust, used as an eco-friendly fuel source.',
      image: '../assets/img/products/Saw Dust Briquettes.png',
    },
    {
      title: 'Mustard Husk',
      description: 'Mustard husk is the outer shell of mustard seeds, used for fuel, compost, or animal bedding.',
      image: '../assets/img/products/Mustard Husk.png',
    },
    {
      title: 'Bagasse Pellets',
      description: 'Bagasse pellets are compressed biofuel made from sugarcane waste, used for energy production.',
      image: '../assets/img/products/Bagasse Pellets.png',
    },
    // {
    //   title: 'Paddy Straw Bale',
    //   description: 'Paddy straw bale is compressed rice straw, used for fuel or farming.',
    //   image: '../assets/img/products/Paddy Straw Bale.png',
    // },
    {
      title: 'Rice Husk',
      description: 'Rice husk is the outer shell of rice, used for fuel or fertilizer.',
      image: '../assets/img/products/Rice Husk.png',
    },
    {
      title: 'Rice Straw Bale',
      description: 'Rice straw bale is compacted rice straw,used for bioenergy or farming.',
      image: '../assets/img/products/Rice Straw Bale.png',
    },
    {
      title: 'Saw Dust Pellets',
      description: 'Sawdust pellets are compressed sawdust, used as a renewable fuel.',
      image: '../assets/img/products/Saw Dust Pellets.png',
    },
    {
      title: 'Soyabean Stalk',
      description: 'Soybean stalk is the leftover stem from soybean harvest, used in compost, feed, or biofuel.',
      image: '../assets/img/products/Soyabean Stalk.png',
    },
    // {
    //   title: 'Rice Straw',
    //   description: 'Rice straw is the fibrous residue left after harvesting rice, often used bioenergy production.',
    //   image: '../assets/img/products/Rice Straw.png',
    // },
    {
      title: 'Mustard Husk Briquette',
      description: "Mustard husk briquette is compressed mustard husk, used as an eco-friendly energy source.",
      image: '../assets/img/products/mustard husk Briquette.png',
    },
    {
      title: 'Biomass Pellets',
      description: 'Biomass pellets are compact fuel made from organic waste, used for energy production.',
      image: '../assets/img/products/Biomass Pellets.png',
    },
    {
      title: 'Pressmud',
      description: 'Pressmud is a nutrient-rich by-product of sugar production, commonly used as fertilizer or in biogas.',
      image: '../assets/img/products/Pressmud.png',
    },
    // {
    //   title: 'Rice Husk',
    //   description: 'Rice Husk hard outer covering of rice grains major agriculture product',
    //   image: '../assets/img/products/Rice husk-2.png',
    // },
    // {
    //   title: 'Bagasse',
    //   description: "A dry, pulpy material that's a byproduct of the sugar industry",
    //   image: '../assets/img/products/Bagasse-3.png',
    // },
    // {
    //   title: 'Rice Husk',
    //   description: 'Rice Husk hard outer covering of rice grains major agriculture product',
    //   image: 'https://upload.wikimedia.org/wikipedia/commons/d/d6/Rice_chaffs.jpg',
    // },
    // {
    //   title: 'Rice Husk',
    //   description: 'Rice Husk hard outer covering of rice grains major agriculture product',
    //   image: 'https://upload.wikimedia.org/wikipedia/commons/d/d6/Rice_chaffs.jpg',
    // },
    // {
    //   title: 'Rice Husk',
    //   description: 'Rice Husk hard outer covering of rice grains major agriculture product',
    //   image: 'https://upload.wikimedia.org/wikipedia/commons/d/d6/Rice_chaffs.jpg',
    // },
    // {
    //   title: 'Rice Husk',
    //   description: 'Rice Husk hard outer covering of rice grains major agriculture product',
    //   image: 'https://upload.wikimedia.org/wikipedia/commons/d/d6/Rice_chaffs.jpg',
    // },
    // {
    //   title: 'Loose',
    //   description: 'Rice Husk hard outer covering of rice grains major agriculture product',
    //   image: 'https://upload.wikimedia.org/wikipedia/commons/d/d6/Rice_chaffs.jpg',
    // },
    // {
    //   title: 'Rice Husk',
    //   description: 'Rice Husk hard outer covering of rice grains major agriculture product',
    //   image: 'https://upload.wikimedia.org/wikipedia/commons/d/d6/Rice_chaffs.jpg',
    // },
    // {
    //   title: 'Rice Husk',
    //   description: 'Rice Husk hard outer covering of rice grains major agriculture product',
    //   image: 'https://upload.wikimedia.org/wikipedia/commons/d/d6/Rice_chaffs.jpg',
    // },
    // {
    //   title: 'Rice Husk',
    //   description: 'Rice Husk hard outer covering of rice grains major agriculture product',
    //   image: 'https://upload.wikimedia.org/wikipedia/commons/d/d6/Rice_chaffs.jpg',
    // },
    // {
    //   title: 'Rice Husk',
    //   description: 'Rice Husk hard outer covering of rice grains major agriculture product',
    //   image: 'https://upload.wikimedia.org/wikipedia/commons/d/d6/Rice_chaffs.jpg',
    // },
    // {
    //   title: 'Rice Husk',
    //   description: 'Rice Husk hard outer covering of rice grains major agriculture product',
    //   image: 'https://upload.wikimedia.org/wikipedia/commons/d/d6/Rice_chaffs.jpg',
    // },
    // {
    //   title: 'Rice Husk',
    //   description: 'Rice Husk hard outer covering of rice grains major agriculture product',
    //   image: 'https://upload.wikimedia.org/wikipedia/commons/d/d6/Rice_chaffs.jpg',
    // },
    // {
    //   title: 'Rice Husk',
    //   description: 'Rice Husk hard outer covering of rice grains major agriculture product',
    //   image: 'https://upload.wikimedia.org/wikipedia/commons/d/d6/Rice_chaffs.jpg',
    // },
    // {
    //   title: 'Rice Husk',
    //   description: 'Rice Husk hard outer covering of rice grains major agriculture product',
    //   image: 'https://upload.wikimedia.org/wikipedia/commons/d/d6/Rice_chaffs.jpg',
    // },
    // {
    //   title: 'Rice Husk',
    //   description: 'Rice Husk hard outer covering of rice grains major agriculture product',
    //   image: 'https://upload.wikimedia.org/wikipedia/commons/d/d6/Rice_chaffs.jpg',
    // },
    // // Add more product objects here
  ];

//   const indexOfLastProduct = currentPage * productsPerPage;
//   const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
//   const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

//   const totalPages = Math.ceil(products.length / productsPerPage);

//   const handleNextPage = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const handlePrevPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   const handlePageClick = (page) => {
//     setCurrentPage(page);
//   };

//   const renderPageNumbers = () => {
//     const pageNumbers = [];
//     for (let i = 1; i <= totalPages; i++) {
//       pageNumbers.push(
//         <button
//           key={i}
//           onClick={() => handlePageClick(i)}
//           className={`mx-1 px-3 py-1 rounded-full ${currentPage === i ? 'bg-[#f6b60d] text-white' : 'bg-gray-200 text-gray-700'} hover:bg-[#d99d0c] transition duration-300`}
//         >
//           {i}
//         </button>
//       );
//     }
//     return pageNumbers;
//   };

//   return (
//     <div className="container mx-auto px-4 py-8">
//       <h1 className="text-3xl font-semibold mb-6 text-yellow-500">Our Collection Of Products</h1>

//       {/* Product Cards */}
//       <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
//         {currentProducts.map((product, index) => (
//           <div key={index} className="card shadow-lg rounded-lg overflow-hidden">
//             <img src={product.image} className="card-img-top" alt={product.title} />
//             <div className="card-body p-4 bg-white">
//               <h5 className="card-title text-lg font-semibold text-[#372800]">{product.title}</h5>
//               <p className="card-text text-gray-600 mb-4">{product.description}</p>
//               <a href="/post-requirement" className="bg-[#f6b60d] hover:bg-[#d99d0c] text-white px-4 py-2 rounded-lg shadow-md">Ask for a Quote</a>
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Pagination Controls */}
//       <div className="flex justify-center items-center mt-8">
//         <button
//           onClick={handlePrevPage}
//           className={`mx-1 px-4 py-2 rounded-full bg-gray-200 text-gray-700 hover:bg-[#f6b60d] hover:text-white transition duration-300 ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
//           disabled={currentPage === 1}
//         >
//           Previous
//         </button>
//         {renderPageNumbers()}
//         <button
//           onClick={handleNextPage}
//           className={`mx-1 px-4 py-2 rounded-full bg-gray-200 text-gray-700 hover:bg-[#f6b60d] hover:text-white transition duration-300 ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
//           disabled={currentPage === totalPages}
//         >
//           Next
//         </button>
//       </div>
//     </div>
//   );
// };

return (
  <div className="container mx-auto px-4 py-8">
    <h1 className="text-3xl font-semibold mb-6 text-yellow-500">Our Collection Of Products</h1>

    {/* Product Cards */}
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      {products.map((product, index) => (
        <div key={index} className="card shadow-lg rounded-lg overflow-hidden">
          <img src={product.image} className="card-img-top" alt={product.title} />
          <div className="card-body p-4 bg-white">
            <h5 className="card-title text-lg font-semibold text-[#372800]">{product.title}</h5>
            <p className="card-text text-gray-600 mb-4">{product.description}</p>
            <a href="/post-requirement" className="bg-[#f6b60d] hover:bg-[#d99d0c] text-white px-4 py-2 rounded-lg shadow-md">Ask for a Quote</a>
          </div>
        </div>
      ))}
    </div>
  </div>
);
};

export default ProductDetail;
